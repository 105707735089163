<template>
  <div class="update-affiliate-reward-setting-container wrapper">
    <CRow>
      <CCol lg="9" md="9">
        <CCard>
          <CCardHeader>
            <h1>{{$t('CLAIM_AFFILIATE_REWARD_SETTING_LABEL_TITLE')}}</h1>
            <p>{{$t('CLAIM_AFFILIATE_REWARD_SETTING_LABEL_SUB_TITLE')}}</p>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody v-if="!isLoading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <CRow>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_COSMOS"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_COSMOS')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimAtom" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ATOM')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ATOM_NETWORK_FEE"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_ATOM_NETWORK_FEE_LABEL')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimAtomNwFee" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ATOM')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_IRISNET"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_IRISNET')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimIris" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_IRIS')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_IRIS_NETWORK_FEE"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_IRIS_NETWORK_FEE_LABEL')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimIrisNwFee" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_IRIS')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ONG"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ONG')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimOng" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ONG')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ONG_NETWORK_FEE"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_ONG_NETWORK_FEE_LABEL')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimOngNwFee" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ONG')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_VALIDATOR_LABEL_TEZOS"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_TEZOS')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimXtz" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_XTZ')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_XTZ_NETWORK_FEE"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_XTZ_NETWORK_FEE_LABEL')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimXtzNwFee" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_XTZ')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_VALIDATOR_LABEL_HARMONY"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_HARMONY')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimOne" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ONE')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                  <CCol lg="6" md="6">
                    <ValidationProvider
                      name="CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ONE_NETWORK_FEE"
                      rules="required|min_value:0"
                    >
                      <CRow class="form-group" slot-scope="{ errors }">
                        <CCol lg="6" md="6" class="label-group">
                          <label
                            class="col-form-label"
                          >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_ONE_NETWORK_FEE_LABEL')}}</label>
                        </CCol>
                        <CCol lg="6" md="6">
                          <div class="input-group">
                            <input type="text" class="form-control" v-model="config.claimOneNwFee" />
                            <div class="input-group-prepend">
                              <div
                                class="input-group-text"
                              >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ONE')}}</div>
                            </div>
                          </div>

                          <span class="error-msg-wrap">
                            <span class="error-msg">{{errors[0]}}</span>
                          </span>
                        </CCol>
                      </CRow>
                    </ValidationProvider>
                  </CCol>
                </CRow>

                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton
                      v-if="isAllowedUpdating"
                      color="primary"
                      class="btn btn-primary"
                      @click="onSubmit"
                    >{{$t('CLAIM_AFFILIATE_REWARD_SETTING_BUTTON_SAVE')}}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import responseHelper from '@/utils/response-helper';
import { MembershipOrderStatus } from '@/enums';
import { endpoints, env } from '@/constants';

export default {
  name: 'ClaimAffiliateRewardSetting',
  components: {
    VclList,
  },
  data() {
    return {
      config: {},
      isLoading: true,
      isProcessing: false,
      isAllowedUpdating: false,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    this.checkPermissions();
    this.getAffiliateRewardSetting();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'AFFILIATE_UPDATE_CLAIM_REWARD_SETTING') {
            this.isAllowedUpdating = true;
          }
        });
      }
    },
    async getAffiliateRewardSetting() {
      try {
        const result = await this.$http.get(endpoints.getAffiliateRewardSetting, {});
        this.config.claimAtom = Number(result.data.claim_affiliate_reward_atom);
        this.config.claimIris = Number(result.data.claim_affiliate_reward_iris);
        this.config.claimOng = Number(result.data.claim_affiliate_reward_ong);
        this.config.claimXtz = Number(result.data.claim_affiliate_reward_xtz);
        this.config.claimOne = Number(result.data.claim_affiliate_reward_one);
        this.config.claimAtomNwFee = Number(result.data.claim_affiliate_reward_atom_network_fee);
        this.config.claimIrisNwFee = Number(result.data.claim_affiliate_reward_iris_network_fee);
        this.config.claimOngNwFee = Number(result.data.claim_affiliate_reward_ong_network_fee);
        this.config.claimXtzNwFee = Number(result.data.claim_affiliate_reward_xtz_network_fee);
        this.config.claimOneNwFee = Number(result.data.claim_affiliate_reward_one_network_fee);

        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CLAIM_AFFILIATE_REWARD_SETTING_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },

    async onSubmit() {
      this.$refs.form.validate().then(isValid => {
        if (!isValid) {
          return;
        }

        this.update();
      });
    },
    async update() {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;
      try {
        const data = {
          claim_affiliate_reward_atom: this.config.claimAtom,
          claim_affiliate_reward_iris: this.config.claimIris,
          claim_affiliate_reward_ong: this.config.claimOng,
          claim_affiliate_reward_xtz: this.config.claimXtz,
          claim_affiliate_reward_one: this.config.claimOne,
          claim_affiliate_reward_atom_network_fee: this.config.claimAtomNwFee,
          claim_affiliate_reward_iris_network_fee: this.config.claimIrisNwFee,
          claim_affiliate_reward_ong_network_fee: this.config.claimOngNwFee,
          claim_affiliate_reward_xtz_network_fee: this.config.claimXtzNwFee,
          claim_affiliate_reward_one_network_fee: this.config.claimOneNwFee,
        };
        const result = await this.$http.put(endpoints.updateAffiliateRewardSetting, data);

        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('CLAIM_AFFILIATE_REWARD_SETTING_NOTIFY_SUCCESS_TITLE'),
          text: this.$t('CLAIM_AFFILIATE_REWARD_SETTING_NOTIFY_SUCCESS_MESSAGE'),
        });
        this.isProcessing = false;
      } catch (err) {
        this.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CLAIM_AFFILIATE_REWARD_SETTING_NOTIFY_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.update-affiliate-reward-setting-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 15px;
      }
      p {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: #3e4b65;
        padding-top: 15px;
        margin-bottom: 15px;
      }
    }
    h2 {
      color: #657187;
      font-size: 16px;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 0px;
    }

    .form-group {
      margin-bottom: 0.5rem;
    }
    .label-group {
      text-align: right;
      label {
        font-size: 14px;
        font-weight: 300;
        color: #3e4b65;
      }
    }
    .input-group {
      input {
        font-size: 16px;
        border-radius: 4px !important;
        color: #3a4a7f;
      }
      .input-group-prepend {
        .input-group-text {
          width: 65px;
          background-color: unset;
          border: none;
        }
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 4px;
        width: 145px;
        height: 36px;
        padding: 0 30px;
        color: #fff;
        margin: 30px 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }
      }
    }
  }
}
</style>
