var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"update-affiliate-reward-setting-container wrapper"},[_c('CRow',[_c('CCol',{attrs:{"lg":"9","md":"9"}},[_c('CCard',[_c('CCardHeader',[_c('h1',[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_LABEL_TITLE')))]),_c('p',[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_LABEL_SUB_TITLE')))])]),(_vm.isLoading)?_c('CCardBody',[_c('vcl-list')],1):_vm._e(),(!_vm.isLoading)?_c('CCardBody',[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('CForm',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('CRow',[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_COSMOS","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_COSMOS')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimAtom),expression:"config.claimAtom"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimAtom)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimAtom", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ATOM')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ATOM_NETWORK_FEE","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_ATOM_NETWORK_FEE_LABEL')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimAtomNwFee),expression:"config.claimAtomNwFee"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimAtomNwFee)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimAtomNwFee", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ATOM')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_IRISNET","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_IRISNET')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimIris),expression:"config.claimIris"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimIris)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimIris", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_IRIS')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_IRIS_NETWORK_FEE","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_IRIS_NETWORK_FEE_LABEL')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimIrisNwFee),expression:"config.claimIrisNwFee"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimIrisNwFee)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimIrisNwFee", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_IRIS')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ONG","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ONG')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimOng),expression:"config.claimOng"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimOng)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimOng", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ONG')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ONG_NETWORK_FEE","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_ONG_NETWORK_FEE_LABEL')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimOngNwFee),expression:"config.claimOngNwFee"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimOngNwFee)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimOngNwFee", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ONG')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_VALIDATOR_LABEL_TEZOS","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_TEZOS')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimXtz),expression:"config.claimXtz"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimXtz)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimXtz", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_XTZ')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_XTZ_NETWORK_FEE","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_XTZ_NETWORK_FEE_LABEL')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimXtzNwFee),expression:"config.claimXtzNwFee"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimXtzNwFee)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimXtzNwFee", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_XTZ')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1)],1),_c('CRow',[_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_VALIDATOR_LABEL_HARMONY","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_HARMONY')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimOne),expression:"config.claimOne"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimOne)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimOne", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ONE')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"CLAIM_AFFILIATE_REWARD_SETTING_FIELD_LABEL_ONE_NETWORK_FEE","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return _c('CRow',{staticClass:"form-group"},[_c('CCol',{staticClass:"label-group",attrs:{"lg":"6","md":"6"}},[_c('label',{staticClass:"col-form-label"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_ONE_NETWORK_FEE_LABEL')))])]),_c('CCol',{attrs:{"lg":"6","md":"6"}},[_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.config.claimOneNwFee),expression:"config.claimOneNwFee"}],staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":(_vm.config.claimOneNwFee)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.config, "claimOneNwFee", $event.target.value)}}}),_c('div',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_FIELD_TEXT_SYMBOL_ONE')))])])]),_c('span',{staticClass:"error-msg-wrap"},[_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(errors[0]))])])])],1)}}],null,true)})],1)],1),_c('CRow',{staticClass:"form-actions mt-3"},[_c('CCol',{attrs:{"lg":"12","md":"12"}},[(_vm.isAllowedUpdating)?_c('CButton',{staticClass:"btn btn-primary",attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t('CLAIM_AFFILIATE_REWARD_SETTING_BUTTON_SAVE')))]):_vm._e()],1)],1)],1)]}}],null,false,3486843342)})],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }